@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.name {
  text-transform: uppercase;
  color: $text-primary-color;
  font-weight: 600;
  @include font-2xl;
}

.row {
  display: flex;
  align-items: center;
  gap: 40px;
}

.cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cellKey {
  font-weight: 600;
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-lg;
}

.cellValue {
  color: $subtext-color;
  @include font-lg;
}

.grades {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.grade {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid $primary-color;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  @include transition(border-color, color, background-color);

  &_disabled {
    border-color: $input-placeholder-color;
    cursor: not-allowed;

    .gradeName,
    .gradePrice {
      color: $input-placeholder-color;
    }
  }

  &_active,
  &:hover:not(&_disabled) {
    background-color: $primary-color;
    color: $text-secondary-color;

    .gradeName,
    .gradePrice {
      color: $text-secondary-color;
    }
  }
}

.gradeName {
  color: $primary-color;
  font-weight: 600;
  text-transform: uppercase;
  @include transition(color);
  @include font-lg;
}

.gradePrice {
  color: $text-primary-color;
  font-weight: 600;
  @include transition(color);
  @include font-2xl;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@include tablet {
  .button {
    width: unset;
    padding: 0 40px;
  }
}
