@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.imagesContainer {
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-areas:
    "carousel"
    "itineraryButton"
    "name";
}

.carousel {
  grid-area: carousel;
}

.itineraryButton {
  grid-area: itineraryButton;
  background-color: $element-background-secondary;
}

.name {
  grid-area: name;
}

.name {
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-2xl;
}

.details {
  color: $subtext-color;
  @include font-lg;

  tr {
    td:first-of-type {
      color: $text-primary-color;
      padding-right: 20px;
      font-weight: 600;
    }
  }
}

.price {
  display: flex;
  flex-direction: column;

  &_from {
    color: $subtext-color;
    @include font-lg;
  }

  &_value {
    color: $text-primary-color;
    font-weight: 600;
    @include font-2xl;
  }
}

.staterooms {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &_title {
    color: $text-primary-color;
    text-transform: uppercase;
    font-weight: 300;
    @include font-2xl;
  }

  &_subtitle {
    color: $subtext-color;
    @include font-lg;
  }
}

.submitButton {
  margin-top: 20px;
}

.footerNote {
  color: $subtext-color;
  font-size: 12px;
  margin-top: 20px;
}

@include tablet {
  .imagesContainer {
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "carousel carousel itineraryButton"
      "carousel carousel ."
      "name name .";
  }

  .name {
    color: $text-primary-color;
    @include font-4xl;
  }

  .staterooms {
    &_title {
      color: $text-primary-color;
      @include font-3xl;
    }
  }

  .submitButton {
    width: unset;
    padding: 0 80px;
  }
}
