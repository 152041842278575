@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.label {
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-lg;
}

.asterisk {
  color: $error-color;
}

.fieldContainer {
  width: 100%;
  position: relative;
}

.field {
  position: relative;
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: $input-background-color;
  border: 2px solid $input-border-color;
  outline: 0;
  padding: 0 10px;
  cursor: pointer;
  @include transition(border);
  @include font-lg;

  &_error {
    border: 2px solid $error-color;
  }
}

.value {
  color: $input-text-color;

  &::after {
    content: "\25BC";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: $input-placeholder-color;
  }

  &_visible {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.placeholder {
  color: $input-placeholder-color;
}

.items {
  width: 100%;
  max-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 66px;
  z-index: 1;
  overflow-y: scroll;
  @include transition(max-height);

  &_visible {
    max-height: 200px;
  }
}

.item {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: $menu-background-color;
  color: $menu-text-color;
  font-weight: 500;
  cursor: pointer;
  @include transition(background-color, color);

  &_chosen {
    background-color: $menu-text-color;
    color: $menu-background-color;
  }

  &:hover {
    background-color: $menu-text-color;
    color: $menu-background-color;
  }
}

.error {
  height: 24px;
  display: inline-block;
  color: $error-color;
}
