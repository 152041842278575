@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  text-transform: uppercase;
  font-weight: 600;
  @include font-lg;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-transform: uppercase;
  font-weight: 600;
  color: $text-primary-color;
  @include font-lg;
}

.gradeName {
  margin-bottom: 10px !important;
}

.textBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
