@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &_withHeader {
    padding-top: $header-height;
  }
}

.content {
  width: 100%;
  min-height: 100vh;
  flex: 1;
}
