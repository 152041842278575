@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-lg;
}

.fieldContainer {
  width: 100%;
  position: relative;
}

.field {
  width: 100%;
  height: 66px;
  box-sizing: border-box;
  color: $primary-color;
  background-color: $input-background-color;
  border: 2px solid $input-border-color;
  outline: 0;
  padding: 10px;
  resize: none;
  @include transition(background-color, border);
  @include font-lg;

  &_error {
    border: 2px solid $error-color !important;
  }

  &_with_icon {
    padding-right: 48px;
  }

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:disabled {
    background-color: $element-background-secondary;
    cursor: not-allowed;
  }
}

.subtext {
  font-weight: 400;
  color: $subtext-color;
  @include font-base;

  &_small {
    font-weight: 400;
    text-transform: none;
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.error {
  min-height: 24px;
  display: inline-block;
  color: $error-color;
}
