@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.icon {
  min-width: 40px;
  min-height: 40px;

  path {
    fill: $primary-color;
  }
}

.label {
  width: 100%;
  color: $text-primary-color;
  font-weight: 500;
  text-transform: uppercase;
  user-select: none;
  @include font-base;
}
