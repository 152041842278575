.alert {
  display: flex;
  align-items: center;
  gap: 14px;
  background-color: var(--element-background-primary);
  padding: 0 10px;
  border-left: 5px solid var(--primary-color);
  border-radius: 4px;
  color: var(--text-primary-color);
  box-shadow: 0 2px 4px 0 var(--shadow-color);
}

.icon {
  min-width: 22px;
  min-height: 70px;
  font-size: 5em;
  color: var(--svg_icons_primary);
}
