@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  color: $text-primary-color;
  text-transform: uppercase;
  @include font-lg;
}

.asterisk {
  color: $error-color;
}

.fieldContainer {
  position: relative;
}

.field {
  width: 100%;
  height: 66px;
  box-sizing: border-box;
  color: $input-text-color;
  background-color: $input-background-color;
  border: 2px solid $input-border-color;
  outline: 0;
  padding: 0 10px;
  @include transition(border, background-color);
  @include font-lg;

  &_error {
    border: 2px solid $error-color !important;
  }

  &:focus {
    border: 2px solid $input-focus-border-color;
  }

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:disabled {
    background-color: $element-background-secondary;
    cursor: not-allowed;
  }
}

.results {
  width: 100%;
  max-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $menu-text-color;
  position: absolute;
  top: 66px;
  z-index: 1;
  overflow-y: scroll;
  @include transition(max-height);

  &_visible {
    max-height: 200px;
  }
}

.loader {
  margin: 24px 0;
}

.resultsItem {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: $menu-background-color;
  color: $menu-text-color;
  font-weight: 500;
  cursor: pointer;
  @include transition(background-color, color);

  &:hover {
    background-color: $menu-text-color;
    color: $menu-background-color;
  }
}

.chosen {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  box-sizing: border-box;

  &_visible {
    padding: 10px 0;
  }
}

.chosenItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: $menu-background-color;
  color: $menu-text-color;
  padding: 0 10px;
}

.closeIcon {
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
  cursor: pointer;

  path {
    fill: $menu-text-color;
  }
}

.error {
  height: 24px;
  display: inline-block;
  color: $error-color;
}
