@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $element-background-secondary;
}

.content {
  width: 100%;
  max-width: $laptop-width;
  flex: 1;
  display: flex;
  padding: 15px 40px;
  justify-content: space-between;
  box-sizing: border-box;
}

.searchString {
  margin-bottom: 0;
}

.searchEditLink {
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
}
