@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.container {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  color: $subtext-color;
}

.imagesContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.images {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.map {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
}

.name {
  text-transform: uppercase;
  color: $text-primary-color;
  @include font-2xl;
}

.details {
  @include font-lg;

  tr {
    td:first-of-type {
      padding-right: 20px;
      font-weight: 600;
      color: $text-primary-color;
    }
  }
}

.description {
  vertical-align: top;
  color: $subtext-color;
}

.price {
  display: flex;
  flex-direction: column;

  &_from {
    color: $subtext-color;
    @include font-lg;
  }

  &_value {
    font-weight: 600;
    color: $text-primary-color;
    @include font-2xl;
  }
}

.itinerary {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: $subtext-color;
}

.itineraryTitle {
  font-weight: 600;
  color: $text-primary-color;
  @include font-lg;
}

@include tablet {
  .name {
    @include font-4xl;
  }
}

@include laptop {
  .images {
    &_twoColumns {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
